import router from "../../router/router.js";
class WebConnector {
  platform = null;

  constructor() {
    this.platform = "web";
  }
  share(path, text) {
    const title = "Ich hab was Tolles in der Mooi App gefunden!";
    const finalText = `Hi, ich hab das hier in der Mooi-App gefunden: ${text}`;
    const url = `https://mooi-app.de${path}`;
    this.openShareMenu(url, title, finalText);
  }
  openShareMenu(url, title, text) {
    console.log(navigator);
    if (navigator.share) {
      navigator.share({
        title: title,
        text: text,
        url: url,
      });
    } else {
      alert(
        "Leider wird das Teilen von Inhalten von Ihrem Browser nicht unterstützt. Kopieren Sie einfach den Pfad in der Adressleiste und leiten Sie ihn manuell weiter."
      );
    }
  }
  openNewTab(url) {
    window.open(url, "_blank");
  }

  rate() {
    console.log("rate app");
  }
  checkRating() {
    if (localStorage.getItem("userHasRated") === null) {
      let counter = parseInt(localStorage.getItem("ratingCounter")) || 0;
      counter++;
      localStorage.setItem("ratingCounter", counter);
      if (counter > 10) {
        this.rate();
        localStorage.setItem("userHasRated", 1);
      }
    }
  }
}

class AndroidConnector extends WebConnector {
  constructor() {
    super();
    this.platform = "android";
  }
  openShareMenu(url, _, text) {
    const final = `${text}. Hier der Link: ${url}`;
    window.JSI.share(final);
  }

  goBack() {
    console.log("goback");
    router.go(-1);
  }

  rate() {
    //TODO: Android rating
    window.JSI.rate();
  }
}

class IOSConnector extends WebConnector {
  constructor() {
    super();
    this.platform = "ios";
  }
  openShareMenu(url, _, text) {
    const final = `${text}. Hier der Link: ${url}`;
    let data = { id: "share", msg: final };
    this.sendToNative(data);
  }

  sendToNative(data) {
    window.webkit.messageHandlers.JSI.postMessage(data);
  }
  rate() {
    let data = { id: "rate" };
    this.sendToNative(data);
  }
}

if (typeof window.JSI != "undefined") {
  window.nativeConnector = new AndroidConnector();
  console.log("Android Connector");
} else if (typeof window.webkit != "undefined") {
  window.nativeConnector = new IOSConnector();
  console.log("iOS Connector");
} else {
  console.log("Web Connector");
  window.nativeConnector = new WebConnector();
}

export default window.nativeConnector;
