<template>
  <header class="bar d-flex justify-content-between align-items-center">
    <img
      v-if="showLogo"
      src="../assets/images/logos/logo_neu.svg"
      alt=""
      class="icon col-2"
    />
    <div v-else class="col-2" style="z-index: 100">
      <chevron-left-icon @click="goBack"></chevron-left-icon>
    </div>

    <title class="col-8 row justify-content-center align-items-center">
      <div v-html="title" class="text-center"></div>
    </title>
    <div class="col-2" style="z-index: 100">
      <dots-horizontal-icon
        class="float-end"
        @click="userTappedMenuIcon"
      ></dots-horizontal-icon>
    </div>
  </header>
</template>

<script>
import converter from "../assets/helpers/converter.js";
import * as routes from "../router/routeNames.js";
import mainTitle from "../pages/main/mainTitle.js";
import DotsHorizontalIcon from "vue-material-design-icons/DotsHorizontal.vue";
import ChevronLeftIcon from "vue-material-design-icons/ChevronLeft.vue";
export default {
  components: { DotsHorizontalIcon, ChevronLeftIcon },
  inject: ["activePage", "showSideMenu"],
  computed: {
    showLogo() {
      const logoShownOnPage = [
        routes.routeInformationsList,
        routes.routeIngredientsList,
        routes.routeRecipesList,
        routes.routeMainPage,
      ];
      return logoShownOnPage.includes(this.activePage.value) ? true : false;
    },
    title() {
      const season = converter.getSeasonName();
      switch (this.activePage.value) {
        case routes.routeRecipesList:
        case routes.routeRecipePage:
          return `<span class='text-center'><b>${season}</b> - Rezepte</span>`;
        case routes.routeIngredientPage:
        case routes.routeIngredientsList:
          return "<span class='text-center'><b>Saison</b>kalender</span>";
        case routes.routeInformationsList:
        case routes.routeInformationPage:
          return "<span class='text-center'><b>Klima</b>-Tipps</span>";
        case routes.routeMainPage:
          return mainTitle;
        default:
          return this.activePage.value;
      }
    },
  },
  methods: {
    userTappedMenuIcon() {
      this.showSideMenu.value = !this.showSideMenu.value;
    },
    goBack() {
      console.log("history: " + window.history.length);
      if (window.history.length > 1) {
        this.$router.go(-1);
      } else {
        this.$router.push({ name: this.$routeNames.routeMainPage });
      }
    },
  },
  created() {},
};
</script>

<style scoped>
.bar {
  width: 100%;
  min-height: 60px;
  z-index: 100;
  padding: 0 1rem;
  top: 0px;
}

@media only screen and (min-width: 1025) {
  .bar {
    border-bottom: solid 1px;
  }
}

.icon {
  max-height: 40px;
}
</style>
