<template>
  <div class="background" ref="bgSlot" :class="pageClass">
    <div id="mainAppView"><slot></slot></div>
    <figure class="pos1">
      <img :src="completeImagePath(files[0].files[season - 1])" alt="" />
    </figure>
    <figure class="pos2">
      <img :src="completeImagePath(files[1].files[season - 1])" alt="" />
    </figure>
    <figure class="pos3">
      <img :src="completeImagePath(files[2].files[season - 1])" alt="" />
    </figure>
    <figure class="pos4">
      <img :src="completeImagePath(files[3].files[season - 1])" alt="" />
    </figure>
    <figure class="pos5" v-show="showItem">
      <img :src="completeImagePath(files[4].files[season - 1])" alt="" />
    </figure>
    <figure class="pos6" v-show="showItem">
      <img :src="completeImagePath(files[5].files[season - 1])" alt="" />
    </figure>
  </div>
</template>

<script>
import converter from "../../assets/helpers/converter.js";
import files from "./backgrounds.js";
export default {
  inject: ["activePage"],
  data() {
    return {
      imageArray: [],
      files: files,
      showMoreElements: false,
    };
  },
  computed: {
    season() {
      return converter.getSeasonOnMonth();
    },
    pageClass() {
      const r = this.$routeNames;
      switch (this.activePage.value) {
        case r.routeIntroPage:
          return "intro";
        case r.routeMainPage:
          return "home";
        case r.routeRecipesList:
        case r.routeIngredientsList:
        case r.routeInformationsList:
          return "ueberblick";
        case r.routeRecipePage:
          return "einzel-rezept";
        case r.routeIngredientPage:
        case r.routeInformationPage:
          return "einzel";
        default:
          return "";
      }
    },
    showItem() {
      const r = this.$routeNames;
      switch (this.activePage.value) {
        case r.routeRecipePage:
        case r.routeRecipesList:
        case r.routeIngredientsList:
        case r.routeInformationsList:
          return true;
        default:
          return false;
      }
    },
  },
  methods: {
    completeImagePath(img) {
      return `${process.env.BASE_URL}assets/images/backgrounds/${img}.png`;
    },
  },
};
</script>

<style scoped>
#mainAppView {
  z-index: 10;
}

.background {
  position: absolute;
  z-index: 0 !important;
  width: 100%;
}

figure {
  position: absolute;
  margin: 0px 40px;
  z-index: 0 !important;
}
</style>
