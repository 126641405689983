<template>
  <div aria-label="Liste mit den Suchergebnissen">
    <transition-group name="list" class="row my-3">
      <div
        class="col-6 col-md-4 list-elements my-1"
        v-for="item in items"
        :key="Date.now().toString() + '_' + item.id"
        @click="clicked(item.id)"
      >
        <base-list-item :item="item" :showLikes="showLikes"></base-list-item>
        <!-- <v-lazy-image
          :src="item.path"
          :src-placeholder="placeholderImage"
          alt=""
          class="main-picture my-2"
          :aria-label="item.title"
        />
        <p class="text-center">{{ item.title }}</p> -->
      </div>
    </transition-group>
  </div>
</template>

<script>
// import VLazyImage from "v-lazy-image";
import BaseListItem from "../components/UI/BaseListItem.vue";
export default {
  components: {
    // VLazyImage,
    BaseListItem,
  },
  props: ["items", "page", "showLikes"],
  data() {
    return {
      placeholderImage: `${process.env.NODE_ENV}assets/images/loading_spinner.gif`,
    };
  },
  methods: {
    clicked(id) {
      this.$router.push({ name: this.page, params: { id } });
    },
  },
};
</script>

<style scoped>
.list-elements {
  display: inline-block;
  transition: all 0.3s;
}

.list-enter, .list-leave-to /* .list-leave-active below version 2.1.8 */ {
  opacity: 0;
}
.list-move {
  transition: transform 0.3s;
}

.list-leave-active {
  position: absolute;
  filter: blur(10px);
}

.v-lazy-image {
  filter: blur(10px);
  transition: filter 0.5s;
}

.v-lazy-image-loaded {
  filter: blur(0);
}

.input-group-text {
  background: none;
  border: none;
}

.form-control {
  border: none;
  border-radius: 0.75rem;
}

.input-group {
  border-style: dotted;
  border-radius: 0.75rem;
  border-width: 2px;
}
</style>
