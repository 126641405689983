<template>
  <div class="wrapper">
    <div class="active" v-if="active && height" :style="heightAndWidth"></div>
    <div id="mySlot" ref="mySlot" :class="textColor">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: ["active", "borderColor"],
  data() {
    return {
      height: null,
      width: null,
    };
  },
  computed: {
    textColor() {
      return this.active ? "text-white" : "";
    },
    heightAndWidth() {
      let result = {
        height: `${this.height + 2}px`,
        width: `${this.width + 2}px`,
      };
      return result;
    },
  },
  mounted() {
    this.height = this.$refs.mySlot.clientHeight;
    this.width = this.$refs.mySlot.clientWidth;
    if (this.borderColor) {
      this.$refs.mySlot.style.borderColor = this.borderColor;
    }
  },
};
</script>

<style scoped>
.wrapper {
  position: relative;
  width: auto;
  padding: 0px;
}

#mySlot {
  position: relative;
  text-align: center;
  border: 1px dotted;
  border-radius: 5px;
  color: black;
  padding: 2px 5px;
  border-color: black;
}

.active {
  position: absolute;
  background-color: var(--main-color-red);
  border-radius: 5px;
  top: 3px;
  left: 3px;
}
</style>
