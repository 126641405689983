export default {
  setRecipes(state, payload) {
    state.recipes = payload;
  },
  setActiveRecipe(state, payload) {
    state.activeRecipe = payload;
  },
  setIngredients(state, payload) {
    state.ingredients = payload;
  },
  setActiveIngredient(state, payload) {
    state.activeIngredient = payload;
  },
  setShops(state, payload) {
    state.shops = payload;
  },
  setActiveShop(state, payload) {
    state.activeShop = payload;
  },
  setInformations(state, payload) {
    state.informations = payload;
  },
  setActiveInformation(state, payload) {
    state.activeInformation = payload;
  },
  setRecipeSearch(state, payload) {
    state.recipeSearch = payload;
  },
  setIngredientSearch(state, payload) {
    state.ingredientSearch = payload;
  },
  setShoppingList(state, payload) {
    state.shoppingList = payload;
  },
};
