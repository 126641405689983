import * as routeNames from "./routeNames.js";

export default [
  {
    path: "/",
    name: routeNames.routeIntroPage,
    component: () => import("@/pages/intro/IntroPage.vue"),
  },
  {
    path: "/mooi",
    name: routeNames.routeMainPage,
    component: () => import("@/pages/main/MainPage.vue"),
  },
  {
    path: "/rezepte",
    name: routeNames.routeRecipesList,
    component: () => import("@/pages/recipes/RecipesList.vue"),
    props: true,
  },
  {
    name: routeNames.routeRecipePage,
    path: "/rezepte/:id",
    component: () => import("@/pages/recipes/recipe/RecipePage.vue"),
    props: true,
  },
  {
    path: "/zutaten",
    name: routeNames.routeIngredientsList,
    component: () => import("@/pages/ingredients/IngredientsList.vue"),
  },
  {
    name: routeNames.routeIngredientPage,
    path: "/zutaten/:id",
    component: () => import("@/pages/ingredients/IngredientPage.vue"),
    props: true,
  },

  {
    path: "/klimahinweise",
    name: routeNames.routeInformationsList,
    component: () => import("@/pages/informations/InformationsList.vue"),
  },
  {
    name: routeNames.routeInformationPage,
    path: "/klimahinweise/:id",
    component: () => import("@/pages/informations/InformationPage.vue"),
    props: true,
  },
  {
    name: routeNames.routeMapPage,
    path: "/karte",
    component: () => import("@/pages/map/MapPage.vue"),
  },
  {
    name: routeNames.routeMapItemPage,
    path: "/karte/:id",
    component: () => import("@/pages/map/MapPage.vue"),
    props: true,
  },

  {
    name: routeNames.routeAboutPage,
    path: "/ueber-mooi",
    component: () => import("@/pages/abouts/AboutPage.vue"),
  },
  {
    name: routeNames.routeImpressumPage,
    path: "/impressum",
    component: () => import("@/pages/abouts/ImpressumPage.vue"),
  },
  {
    name: routeNames.routePrivacyPage,
    path: "/datenschutz",
    component: () => import("@/pages/abouts/PrivacyPage.vue"),
  },
  {
    name: routeNames.routeShoppingListPage,
    path: "/einkaufsliste",
    component: () => import("@/pages/shoppinglist/ShoppingList.vue"),
  },
  {
    name: routeNames.routeLikedRecipesPage,
    path: "/favoriten",
    component: () => import("@/pages/likedRecipes/LikedRecipes.vue"),
  },
  {
    path: "*",
    redirect: { name: "intro" },
  },
];
