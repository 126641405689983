import axios from "axios";
import { BASE_AJAX_URL } from "../../global.js";
import { markAsLiked, markAsUnliked } from "./localstorage.js";

async function load(table) {
  const response = await axios.get(BASE_AJAX_URL + "load" + table);
  if (!response.data.error) {
    return response.data;
  } else {
    throw new Error(response.data.error);
  }
}

async function like(id) {
  const res = await axios.get(BASE_AJAX_URL + "likeRecipe&id=" + id);
  console.log(res);
  if (!res.data.error) {
    markAsLiked(id);
    return res.data;
  } else {
    throw new Error(res.data.error);
  }
}

async function unlike(id) {
  const res = await axios.post(BASE_AJAX_URL + "unlikeRecipe&id=" + id);
  if (!res.data.error) {
    markAsUnliked(id);
    return res.data;
  } else {
    throw new Error(res.data.error);
  }
}

export { load, like, unlike };
