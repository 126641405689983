<template>
  <div id="app">
    <slider-menu></slider-menu>
    <bottom-nav-bar v-if="showBottomBar"></bottom-nav-bar>
    <main>
      <transition name="router-view">
        <router-view> </router-view>
      </transition>
    </main>
  </div>
</template>

<script>
import BottomNavBar from "./components/BottomNavBar.vue";
import SliderMenu from "./components/SliderMenu.vue";
import { mapActions } from "vuex";
import * as routes from "./router/routeNames.js";
export default {
  name: "App",
  components: {
    BottomNavBar,
    SliderMenu,
  },
  data() {
    return {
      activePage: { value: routes.routeIntroPage },
      showSideMenu: { value: false },
    };
  },
  provide() {
    return { activePage: this.activePage, showSideMenu: this.showSideMenu };
  },
  computed: {
    showBottomBar() {
      const x = this.activePage.value == routes.routeIntroPage ? false : true;
      return x;
    },
  },
  methods: {
    ...mapActions([
      "loadRecipes",
      "loadIngredients",
      "loadShops",
      "loadInformations",
    ]),
  },
  watch: {
    activePage: {
      deep: true,
      handler() {
        console.log("activePage: " + this.activePage.value);
      },
    },
  },
};
</script>

<style scoped>
#app {
  height: 100vh;
  width: 100vw;
  margin-top: 0;
  position: fixed;
}

main {
  overflow-x: hidden;
  overflow-y: scroll;
  width: 100%;
  min-height: calc(100vh - 60px);
  position: relative;
}

main::-webkit-scrollbar {
  --webkit-appearance: none;
  display: none;
}

main {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

@media only screen and (min-width: 1025px) {
  #app {
    margin-left: 50%;
    transform: translateX(-50%);
    width: 1300px;
  }
}

.router-view-enter-active,
.router-view-leave-active {
  transition: opacity 0.8s;
}

.router-view-enter,
.router-view-leave-to {
  opacity: 0;
}
</style>
