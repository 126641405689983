<template>
  <div class="my-3 row">
    <form class="">
      <div class="input-group">
        <input
          type="text"
          class="form-control"
          v-model="enteredSearchTerm"
          aria-descripedby="Suchfeld für Schlagworte"
        />
        <div class="input-group-append" aria-label="Suchicon">
          <search-icon class="input-group-text"></search-icon>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import SearchIcon from "vue-material-design-icons/Magnify.vue";
export default {
  components: { SearchIcon },
  props: ["searchTerm"],
  emits: ["updatedSearchTerm"],
  data() {
    return {
      enteredSearchTerm: "",
      activeSearchTerm: "",
    };
  },

  watch: {
    enteredSearchTerm(val) {
      setTimeout(() => {
        if (val == this.enteredSearchTerm) {
          this.activeSearchTerm = val;
        }
      }, 200);
    },
    activeSearchTerm(val) {
      this.$emit("updatedSearchTerm", val);
    },
  },
  created() {
    this.enteredSearchTerm = this.searchTerm;
  },
};
</script>

<style scoped>
.input-group {
  border: 1px dotted;
  border-radius: 5px !important;
}

.input-group-text,
.input-group-append {
  background: white !important;
  border: none;
  border-radius: 5px;
}

input {
  border: none;
}
</style>
