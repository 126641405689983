import Vue from "vue";
import App from "./App.vue";

import VueScreen from "vue-screen";

import Store from "./store/index.js";
import Router from "./router/router.js";
import * as RouteNames from "./router/routeNames.js";
import * as globals from "./global.js";

import Bootstrap from "bootstrap/dist/css/bootstrap.min.css";
import Styles from "./assets/css/style.css";
import BackgroundStyles from "./assets/css/background_styles.css";

import nativeConnector from "./assets/helpers/nativeConnector.js";

import BaseBg from "./pages/backgrounds/BaseBg.vue";
import ItemPage from "./components/ItemPage.vue";
import BaseList from "./components/BaseList.vue";
import BaseTag from "./components/UI/BaseTag.vue";
import BaseListTitle from "./components/UI/BaseListTitle.vue";
import BaseSearch from "./components/UI/BaseSearch.vue";
import BaseShareButton from "./components/UI/BaseShareButton.vue";
import BaseDivider from "./components/UI/BaseDivider.vue";
import TopNavBar from "./components/TopNavBar.vue";
import ConfirmationDialogue from "./components/ConfirmationDialog.vue";

Vue.use(Bootstrap);
Vue.use(Styles);
Vue.use(BackgroundStyles);
Vue.use(Store);
Vue.use(Router);
Vue.use(VueScreen);

Vue.component("base-bg", BaseBg);
Vue.component("item-page", ItemPage);
Vue.component("base-list", BaseList);
Vue.component("base-tag", BaseTag);
Vue.component("base-list-title", BaseListTitle);
Vue.component("base-search", BaseSearch);
Vue.component("base-share-button", BaseShareButton);
Vue.component("base-divider", BaseDivider);
Vue.component("top-nav-bar", TopNavBar);
Vue.component("confirmation-dialogue", ConfirmationDialogue);

Vue.prototype.$routeNames = RouteNames;
Vue.prototype.$connector = nativeConnector;
Vue.prototype.$globals = globals;

new Vue({
  render: (h) => h(App),
  store: Store,
  router: Router,
}).$mount("#app");
