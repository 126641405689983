import converter from "../assets/helpers/converter.js";
import { like, load, unlike } from "../assets/helpers/loader.js";
import {
  loadShoppingListFromLocalStorage,
  resetShoppingListAtLocalStorage,
  saveShoppingListToLocalStorage,
} from "../assets/helpers/localstorage.js";

export default {
  async loadRecipes({ commit }) {
    try {
      const userLikes = JSON.parse(localStorage.getItem("likes")) ?? [];
      const data = await load("Recipes");
      let recipes = data.data.map((x) => {
        return {
          ...converter.convertSeasonOnData(x),
          tags: x.tags
            .split(",")
            .map((x) => parseInt(x))
            .sort((a, b) => a - b),
          path: converter.convertImagePath("recipes", x.path),
          shop: parseInt(x.shop),
          cleanLink: converter.cleanLink(x.source),
          cleanLinkAuthor: converter.cleanLinkAuthor(x.source),
          likedByUser: userLikes.includes(parseInt(x.id)) ? true : false,
        };
      });
      commit("setRecipes", recipes);
      console.log("recipes loaded");
      console.log(recipes);
    } catch (e) {
      console.log(e);
      //alert(e.error);
    }
  },

  loadRecipe({ commit, getters }, payload) {
    const recipes = getters.getRecipes;
    let data = recipes.find((recipe) => recipe.id == payload);
    let recipe = {
      ...data,
      id: parseInt(data.id),
      portions: parseInt(data.portions),
      published: parseInt(data.published),
      ingredients: data.ingredients
        .map((y) => {
          y.id = parseInt(y.id);
          y.itemOrder = parseInt(y.itemOrder);
          return y;
        })
        .sort((a, b) => {
          return a.itemOrder - b.itemOrder;
        }),
      steps: data.steps
        .map((z) => {
          z.itemOrder = parseInt(z.itemOrder);
          return z;
        })
        .sort((a, b) => {
          return a.itemOrder - b.itemOrder;
        }),
    };
    commit("setActiveRecipe", recipe);
  },

  async loadIngredients({ commit }) {
    try {
      const data = await load("Ingredients");
      const ingredients = data.data
        .map((ingredient) => {
          return {
            ...converter.convertSeasonOnData(ingredient),
            path: converter.convertImagePath("ingredients", ingredient.path),
            cleanLink: converter.cleanLink(ingredient.link),
            cleanLinkAuthor: converter.cleanLinkAuthor(ingredient.link),
          };
        })
        .sort((a, b) => {
          if (a.title > b.title) return 1;
          if (a.title < b.title) return -1;
          return 0;
        });
      commit("setIngredients", ingredients);
      console.log("ingredients loaded");
    } catch (e) {
      //alert(e.error);
      console.log(e);
    }
  },

  loadIngredient({ commit, getters }, payload) {
    const ingredient = getters.getIngredients.find((x) => x.id == payload);
    commit("setActiveIngredient", ingredient);
  },

  async loadShops({ commit }) {
    try {
      const data = await load("Shops");
      const shops = data.data.map((shop) => {
        shop.lat = parseFloat(shop.lat);
        shop.lng = parseFloat(shop.lng);
        shop.path = shop.path
          ? converter.convertImagePath("shops", shop.path)
          : `${process.env.BASE_URL}assets/images/farm.png`;
        return shop;
      });
      commit("setShops", shops);
      console.log("shops loaded");
    } catch (e) {
      //alert(e.error);
      console.log(e);
    }
  },

  loadShop({ commit, getters }, payload) {
    const shop = getters.getShops.find((x) => x.id == payload);
    commit("setActiveShop", shop);
  },

  async loadInformations({ commit }) {
    try {
      const data = await load("Informations");
      const informations = data.data.map((information) => {
        return {
          ...converter.convertSeasonOnData(information),
          path: converter.convertImagePath("informations", information.path),
          cleanLink: converter.cleanLink(information.link),
          cleanLinkAuthor: converter.cleanLinkAuthor(information.link),
        };
      });
      console.log(informations);
      commit("setInformations", informations);
      console.log("informations loaded");
    } catch (e) {
      //alert(e.error);
      console.log(e);
    }
  },

  async likeRecipe({ commit, getters }, payload) {
    try {
      const recipe = getters.getRecipe;
      const newRecipe = {
        ...recipe,
        likes: parseInt(recipe.likes) + 1,
        likedByUser: true,
      };
      const oldRecipes = getters.getRecipes;
      const newRecipes = oldRecipes.map((x) => {
        if (x.id == newRecipe.id) {
          return newRecipe;
        }
        return x;
      });
      commit("setRecipes", newRecipes);
      commit("setActiveRecipe", newRecipe);

      await like(payload);
    } catch (e) {
      console.log(e);
    }
  },
  async unlikeRecipe({ commit, getters }, payload) {
    try {
      const recipe = getters.getRecipe;
      const newRecipe = {
        ...recipe,
        likes: parseInt(recipe.likes) - 1,
        likedByUser: false,
      };
      const oldRecipes = getters.getRecipes;
      const newRecipes = oldRecipes.map((x) => {
        if (x.id == newRecipe.id) {
          return newRecipe;
        }
        return x;
      });
      commit("setRecipes", newRecipes);
      commit("setActiveRecipe", newRecipe);
      await unlike(payload);
    } catch (e) {
      console.log(e);
    }
  },

  loadInformation({ commit, getters }, payload) {
    const information = getters.getInformations.find((x) => x.id == payload);
    commit("setActiveInformation", information);
  },
  resetRecipeSearch({ commit }) {
    commit("setRecipeSearch", null);
  },
  saveRecipeSearch({ commit }, payload) {
    commit("setRecipeSearch", payload);
  },
  resetIngredientSearch({ commit }) {
    commit("setIngredientSearch", null);
  },
  saveIngredientSearch({ commit }, payload) {
    commit("setIngredientSearch", payload);
  },
  removeItemFromShoppingList({ commit, getters }, payload) {
    const newShoppingList = getters.getShoppingList.filter(
      (x) => x.id != payload
    );
    commit("setShoppingList", newShoppingList);
    saveShoppingListToLocalStorage(newShoppingList);
  },
  toggleShoppingListItemMark({ commit, getters }, payload) {
    const oldShoppingList = getters.getShoppingList;
    let item = oldShoppingList.find((x) => x.id == payload);
    item.marked = !item.marked;
    let newShoppingList = oldShoppingList.filter((x) => x.id != payload);
    if (item.marked) {
      newShoppingList.push(item);
    } else {
      newShoppingList.unshift(item);
    }

    commit("setShoppingList", newShoppingList);
    saveShoppingListToLocalStorage(newShoppingList);
  },
  resetShoppingList({ commit }) {
    commit("setShoppingList", []);
    resetShoppingListAtLocalStorage();
  },
  loadShoppingList({ commit }) {
    const shoppingList = loadShoppingListFromLocalStorage() ?? [];
    commit("setShoppingList", shoppingList);
  },
  exportRecipeToShoppingList({ commit, getters }) {
    const recipe = getters.getRecipe;
    const ingredients = recipe.ingredients;
    const filteredIngredients = converter.filterIngredientsForShoppingList(
      ingredients
    );
    const shoppingList = loadShoppingListFromLocalStorage();
    const newShoppingList = [...filteredIngredients, ...shoppingList];
    commit("setShoppingList", newShoppingList);
    saveShoppingListToLocalStorage(newShoppingList);
  },
  addItemToShoppingList({ commit, getters }, payload) {
    const oldShoppingList = getters.getShoppingList;
    const newShoppingList = [...oldShoppingList, payload];
    commit("setShoppingList", newShoppingList);
    saveShoppingListToLocalStorage(newShoppingList);
  },
  increasePortions({ dispatch, getters }) {
    const oldRecipe = getters.getRecipe;
    const portions = parseInt(oldRecipe.portions) + 1;
    dispatch("changeRecipePortionsTo", portions);
  },
  decreasePortions({ dispatch, getters }) {
    const oldRecipe = getters.getRecipe;
    const oldportions = parseInt(oldRecipe.portions);
    const portions = oldportions > 0 ? oldportions - 1 : 0;
    dispatch("changeRecipePortionsTo", portions);
  },
  changeRecipePortionsTo({ commit, getters }, payload) {
    const id = getters.getRecipe.id;
    const oldRecipe = getters.getRecipes.find((x) => x.id == id);
    const ingredients = converter.calculateIngredients(oldRecipe, payload);
    const newRecipe = { ...oldRecipe, ingredients, portions: payload };
    commit("setActiveRecipe", newRecipe);
  },
};
