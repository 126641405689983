<template>
  <div class="bar d-flex justify-content-between align-items-center">
    <router-link
      :to="{ name: $routeNames.routeMainPage }"
      class="col-2 home"
      @click="onClick"
    >
      <div class="link"></div>
    </router-link>
    <router-link
      :to="{ name: $routeNames.routeRecipesList }"
      class="col-2 recipes"
      @click="onClick"
    >
      <div class="link"></div>
    </router-link>
    <router-link
      :to="{ name: $routeNames.routeIngredientsList }"
      class="col-2 ingredients"
      @click="onClick"
    >
      <div class="link"></div>
    </router-link>
    <router-link
      :to="{ name: $routeNames.routeInformationsList }"
      class="col-2 informations"
      @click="onClick"
    >
      <div class="link"></div>
    </router-link>
    <router-link
      :to="{ name: $routeNames.routeMapPage }"
      class="col-2 map"
      @click="onClick"
    >
      <div class="link"></div>
    </router-link>
  </div>
</template>

<script>
export default {
  inject: ["activePage"],
  methods: {
    onClick(event) {
      const nextPage = event.currentTarget.id;
      console.log(
        "bottomNavBar: navigateTo: " +
          nextPage +
          " from: " +
          this.activePage.value
      );
      if (nextPage != this.activePage.value) {
        this.$router.push({ name: event.currentTarget.id });
      }
    },
  },
};
</script>

<style scoped>
.bar {
  position: fixed;
  width: 100%;
  bottom: 0;
  height: 60px;
  background: white;
  border-top: solid 1px grey;
  z-index: 100;
  padding: 0 1rem;
}

img {
  height: 25px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.link {
  height: 25px;
  width: 25px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  background-position: center;
  background-repeat: none;
  background-size: cover;
}

.home > div {
  background-image: url("../assets/images/icons/home.svg");
}
.home.routerLinkActive > div {
  background-image: url("../assets/images/icons/home_active.svg") !important;
}
.recipes > div {
  background-image: url("../assets/images/icons/recipes.svg");
}
.recipes.routerLinkActive > div {
  background-image: url("../assets/images/icons/recipes_active.svg") !important;
}
.ingredients > div {
  background-image: url("../assets/images/icons/ingredients.svg");
}
.ingredients.routerLinkActive > div {
  background-image: url("../assets/images/icons/ingredients_active.svg") !important;
}
.informations > div {
  background-image: url("../assets/images/icons/informations.svg");
}
.informations.routerLinkActive > div {
  background-image: url("../assets/images/icons/informations_active.svg") !important;
}
.map > div {
  background-image: url("../assets/images/icons/map.svg");
}
.map.routerLinkActive > div {
  background-image: url("../assets/images/icons/map_active.svg") !important;
}
</style>
