<template>
  <div :style="computedWidth">
    <base-button-style :active="active" :aria-label="title">
      <span class="text-center">{{ title }}</span>
    </base-button-style>
  </div>
</template>

<script>
import BaseButtonStyle from "./BaseButtonStyle.vue";
export default {
  components: { BaseButtonStyle },
  props: ["title", "active", "width"],
  data() {
    return {
      computedWidth: { width: "auto", padding: "0px" },
    };
  },
  created() {
    if (this.width) {
      this.computedWidth = { width: `${this.width}px` };
    }
  },
};
</script>

<style scoped>
span {
  font-size: 0.75rem;
}
</style>
