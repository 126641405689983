import getters from "./getters.js";
import mutations from "./mutations.js";
import actions from "./actions.js";
import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);
const store = new Vuex.Store({
  state() {
    return {
      months: [
        {
          id: 1,
          title: "Januar",
        },
        { id: 2, title: "Februar" },
        { id: 3, title: "März" },
        { id: 4, title: "April" },
        { id: 5, title: "Mai" },
        { id: 6, title: "Juni" },
        { id: 7, title: "Juli" },
        { id: 8, title: "August" },
        { id: 9, title: "September" },
        { id: 10, title: "Oktober" },
        { id: 11, title: "November" },
        { id: 12, title: "Dezember" },
      ],
      seasons: [
        { id: 1, title: "Frühling" },
        { id: 2, title: "Sommer" },
        { id: 3, title: "Herbst" },
        { id: 4, title: "Winter" },
      ],

      recipes: null,
      ingredients: null,
      shops: null,
      informations: null,
      activeRecipe: null,
      activeInformation: null,
      activeShop: null,
      activeIngredient: null,
      recipeSearch: null,
      ingredientSearch: null,
      shoppingList: [
        { id: 1, amount: 1, unit: "kleine", title: "Tomaten", marked: false },
        {
          id: 2,
          amount: 1,
          unit: "mittlere",
          title: "Zucchini",
          marked: false,
        },
        { id: 3, amount: 500, unit: "gr.", title: "Mehl", marked: false },
        { id: 4, amount: 0.5, unit: "Liter", title: "Milch", marked: false },
        {
          id: 5,
          amount: 1,
          unit: "großer",
          title: "Blumenkohl",
          marked: false,
        },
        { id: 6, amount: 2, unit: "TL", title: "Kreuzkümmel", marked: false },
        {
          id: 7,
          amount: 1,
          unit: "TL",
          title: "Koriandersamen",
          marked: false,
        },
      ],
    };
  },
  getters: getters,
  mutations: mutations,
  actions: actions,
});

export default store;
