<template>
  <div class="list-item">
    <div class="picturebox" :style="{ backgroundImage: `url('${imageUrl}')` }">
      <base-like v-if="showLikes" :item="item"> </base-like>
    </div>
    <p class="text-center py-0 mb-0 mt-1">{{ item.title }}</p>
    <p
      v-if="item.season.length > 1"
      class="text-center duration m-0 p-0 text-muted"
    >
      {{ seasonDuration }}
    </p>
  </div>
</template>

<script>
import BaseLike from "./BaseLike.vue";
import converter from "../../assets/helpers/converter.js";
export default {
  components: { BaseLike },
  props: ["item", "showLikes"],
  data() {
    return {
      image: "",
      imageUrl: "",
      imageHasLoaded: false,
    };
  },
  computed: {
    seasonDuration() {
      return converter.getSeasonDurationOfIngredients(this.item.season);
    },
  },
  methods: {
    setImage() {
      this.imageUrl = this.item.path;
      this.image = "";
      let that = this;
      let newImage = new Image();
      newImage.onload = () => {
        that.image = that.imageUrl;
      };
      newImage.src = this.imageUrl;
    },
  },
  mounted() {
    this.setImage();
  },
};
</script>

<style scoped>
.picturebox {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  border-radius: 15px;
}

.list-item {
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 120px;
  background-color: white;

  animation: fadeIn 0.25s;
  -moz-animation: fadeIn 0.25s;
  -webkit-animation: fadeIn 0.25s;
}

.list-item p {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  line-height: 1.3rem;
}

@keyframes fadeIn {
  0% {
    filter: (blur(10px));
  }
  100% {
    filter: none;
  }
}

@media only screen and (min-width: 768px) {
  .picturebox {
    height: 150px;
  }
}
</style>