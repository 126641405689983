function markAsLiked(id) {
  const oldLikes = JSON.parse(localStorage.getItem("likes")) ?? [];
  const newLikes = [id, ...oldLikes];
  localStorage.setItem("likes", JSON.stringify(newLikes));
}

function markAsUnliked(id) {
  const oldLikes = JSON.parse(localStorage.getItem("likes")) ?? [];
  const newLikes = oldLikes.filter((x) => x != id);
  localStorage.setItem("likes", JSON.stringify(newLikes));
}

function saveShoppingListToLocalStorage(shoppingList) {
  localStorage.setItem("shoppingList", JSON.stringify(shoppingList));
}

function loadShoppingListFromLocalStorage() {
  const shoppingList = JSON.parse(localStorage.getItem("shoppingList")) ?? [];
  return shoppingList;
}

function resetShoppingListAtLocalStorage() {
  localStorage.setItem("shoppingList", JSON.stringify([]));
}

export {
  markAsLiked,
  markAsUnliked,
  saveShoppingListToLocalStorage,
  loadShoppingListFromLocalStorage,
  resetShoppingListAtLocalStorage,
};
