const routeRecipesList = "Rezepte";
const routeRecipePage = "Rezept";
const routeIngredientsList = "Zutaten";
const routeIngredientPage = "Zutat";
const routeInformationsList = "Klimahinweise";
const routeInformationPage = "Klimahinweis";
const routeIntroPage = "intro";
const routeMainPage = "Mooi";
const routeMapPage = "Karte";
const routeMapItemPage = "Geschäft";
const routeAboutPage = "Über Mooi";
const routeImpressumPage = "Impressum";
const routePrivacyPage = "Datenschutz";
const routeShoppingListPage = "Einkaufsliste";
const routeLikedRecipesPage = "Favoriten";

export {
  routeInformationPage,
  routeInformationsList,
  routeIngredientPage,
  routeIngredientsList,
  routeRecipePage,
  routeRecipesList,
  routeIntroPage,
  routeMainPage,
  routeMapPage,
  routeMapItemPage,
  routeAboutPage,
  routeImpressumPage,
  routePrivacyPage,
  routeShoppingListPage,
  routeLikedRecipesPage,
};
