const files = [
  {
    pos: 1,
    files: ["holunder", "erdbeere", "birne", "zwiebel"],
  },
  {
    pos: 2,
    files: ["spargel", "tomate", "kuerbis", "haselnuss"],
  },
  {
    pos: 3,
    files: ["schnittlauch", "erbsen", "brokkoli", "moehre"],
  },
  {
    pos: 4,
    files: ["salat", "tarragon", "brokkoli", "moehre"],
  },
  {
    pos: 5,
    files: ["spargel", "tomate", "kuerbis", "haselnuss"],
  },
  {
    pos: 6,
    files: ["holunder", "erdbeere", "birne", "zwiebel"],
  },
];

export default files;
