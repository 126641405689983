import converter from "../assets/helpers/converter";
import { cloneDeep } from "lodash";

export default {
  getRecipes(state) {
    return state.recipes
      ? state.recipes
          .slice()
          .sort((a, b) => parseInt(b.likes) - parseInt(a.likes))
      : [];
  },

  getRecipe(state) {
    return state.activeRecipe;
  },

  getIngredients(state) {
    return state.ingredients ? state.ingredients.slice() : [];
  },

  getIngredient(state) {
    return state.activeIngredient;
  },

  getInformations(state) {
    return state.informations ? state.informations.slice() : [];
  },

  getInformation(state) {
    return state.activeInformation;
  },

  getShops(state) {
    return state.shops ? state.shops.slice() : [];
  },

  getShop(state) {
    return state.activeShop ? cloneDeep(state.activeShop) : null;
  },
  getRandomSeasonRecipe(state) {
    const recipes = state.recipes.slice();
    const randomRecipe = converter.getRandomSeasonElement(recipes);
    if (!randomRecipe) {
      const r = Math.floor(Math.random() * recipes.length);
      return recipes[r].id;
    }
    return randomRecipe;
  },
  getRandomSeasonIngredient(state) {
    const ingredients = state.ingredients.slice();
    const randomIngredients = ingredients.filter((item) => {
      const month = converter.getMonth();
      return item.season.includes(month.toString());
    });
    const r = Math.floor(Math.random() * randomIngredients.length);
    return randomIngredients[r].id;
  },
  getRandomSeasonInformation(state) {
    const informations = state.informations.slice();
    const r = Math.floor(Math.random() * informations.length);
    return informations[r].id;
  },
  getRandomShop(state) {
    const shops = state.shops.slice();
    const r = Math.floor(Math.random() * shops.length);
    return shops[r].id;
  },
  getRecipeSearch(state) {
    return state.recipeSearch ? cloneDeep(state.recipeSearch) : null;
  },
  getIngredientSearch(state) {
    return state.ingredientSearch ? cloneDeep(state.ingredientSearch) : null;
  },
  getShoppingList(state) {
    return state.shoppingList.slice();
  },
};
