<template>
  <base-bg>
    <div>
      <top-nav-bar></top-nav-bar>
      <div class="page" v-if="item">
        <section class="container">
          <img
            :src="item.path"
            alt=""
            class="main-picture"
            :aria-label="item.title"
          />
          <h1 class="text-center my-4 text-handwriting">{{ item.title }}</h1>
          <h2
            v-if="item.subtitle"
            class="my-3 text-center fst-italic text-libre fs-6"
          >
            {{ item.subtitle }}
          </h2>
          <div class="row">
            <router-link
              class="text-center"
              v-if="type == 'ingredient'"
              :to="{
                name: $routeNames.routeRecipesList,
                params: { searchLink: item.title },
              }"
              ><span class="text-uppercase text-center border-bottom author"
                >Rezepte mit {{ item.title }}</span
              ></router-link
            >
          </div>
          <p class="my-2 text-muted text-center" v-if="type == 'ingredient'">
            {{ seasonDurationOfIngredients }}
          </p>
          <p class="my-3 description" v-html="markDown"></p>
          <a
            :href="item.cleanLink"
            class="my-3 text-break text-decoration-underline"
            target="_blank"
            v-if="item.link != ''"
            >Quelle: {{ item.cleanLinkAuthor }}</a
          >
          <p class="my-4" v-if="item.photorights != ''">
            Abbildung: {{ item.photorights }}
          </p>
        </section>
        <div class="container my-4" v-if="$connector.platform != 'web'">
          <base-share-button :text="item.title"></base-share-button>
        </div>
      </div>
    </div>
  </base-bg>
</template>

<script>
import marked from "marked";
import converter from "../assets/helpers/converter.js";
export default {
  props: ["item", "type"],
  computed: {
    markDown() {
      console.log(this.item);
      return marked(this.item.description);
    },
    seasonDurationOfIngredients() {
      return converter.getSeasonDurationOfIngredients(this.item.season);
    },
  },
  created() {
    console.log(this.type);
  },
};
</script>

<style scoped>
.description {
  text-align: justify;
}

.link {
  display: inline-block;
}

.link:after {
  height: 12px;
  width: 20px;
  display: inline-block;
  background-image: url("../assets/images/icons/arrowRight.svg");
  background-size: 15px 15px;
  background-position: center;
  background-repeat: no-repeat;
  content: "";
  margin-left: 4px;
}
</style>
