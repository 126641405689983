export default {
  getSeasonName(val) {
    const month = val ? val : this.getMonth();
    const seasonId = this.getSeasonOnMonth(month);
    return seasons.find((season) => season.id == seasonId).title || "";
  },

  getSeasonOnMonth(val) {
    const month = val ? val : this.getMonth();
    switch (parseInt(month)) {
      case 3:
      case 4:
      case 5:
        return 1;
      case 6:
      case 7:
      case 8:
        return 2;
      case 9:
      case 10:
      case 11:
        return 3;
      case 12:
      case 1:
      case 2:
        return 4;
      default:
        return 2;
    }
  },

  getRandomSeasonElement(items) {
    const filteredItems = items.filter((item) =>
      item.seasons.includes(this.getSeasonOnMonth())
    );
    if (filteredItems.length === 0) {
      return null;
    }
    const r = Math.floor(Math.random() * filteredItems.length);
    return filteredItems[r].id;
  },

  getMonth() {
    return new Date().getMonth() + 1;
  },

  getMonthNameOnMonth(id) {
    let _id = null;
    if (!id) {
      _id = this.getMonth();
    } else {
      _id = id;
    }

    const name = monthes.find((month) => month.id == _id).title || "";
    return name;
  },

  convertSeasonOnData(obj) {
    const months = obj.season.split(",").map((month) => parseInt(month));
    const seasons = months.map((x) => this.getSeasonOnMonth(x));
    const seasonNames = months.map((x) => this.getSeasonName(x));
    return {
      ...obj,
      months: months,
      seasons: seasons,
      seasonNames: seasonNames,
    };
  },
  convertImagePath(folder, image) {
    const _pos = image.lastIndexOf("/");
    const _image = image.substr(_pos + 1, image.length);
    const path = "data/" + folder + "/" + _image;
    return "https://mooi-app.de/" + path;
  },
  cleanLink(link) {
    //check for https
    let cleanedLink = "";
    if (link.includes("http")) {
      let pos = link.indexOf("http");
      cleanedLink = link.substr(pos, link.length);
    } else if (link.includes("www")) {
      let pos = link.indexOf("www");
      cleanedLink = "https://" + link.substr(pos, link.length);
    }
    return cleanedLink;
  },
  cleanLinkAuthor(link) {
    let cleanedLink = link;
    if (link.includes("??")) {
      let pos = link.indexOf("??");
      cleanedLink = link.substr(0, pos);
    } else {
      if (link.includes("http")) {
        let pos = link.indexOf("http");
        cleanedLink = pos > 0 ? link.substr(0, pos) : link;
      } else if (link.includes("www")) {
        let pos = link.indexOf("www");
        cleanedLink = pos > 0 ? link.substr(0, pos) : link;
      }
    }
    return cleanedLink;
  },

  getSeasonDurationOfIngredients(seasonString) {
    const seasons = seasonString.split(",");
    let lastVal = null;
    let index = null;
    for (const [i, val] of seasons.entries()) {
      if (lastVal) {
        if (val - lastVal > 1) {
          index = i;
          break;
        }
        lastVal = val;
      } else {
        lastVal = val;
      }
    }

    if (index) {
      return (
        this.getMonthNameOnMonth(seasons[index]) +
        " - " +
        this.getMonthNameOnMonth(seasons[index - 1])
      );
    } else {
      return (
        this.getMonthNameOnMonth(seasons[0]) +
        " - " +
        this.getMonthNameOnMonth(seasons[seasons.length - 1])
      );
    }
  },

  filterIngredientsForShoppingList(ingredients) {
    const filterTerms = ["salz", "wasser", "pfeffer"];
    ingredients = ingredients.filter((x) => {
      let found = false;
      for (const term of filterTerms) {
        if (
          x.title
            .toLowerCase()
            .trim()
            .includes(term)
        ) {
          found = true;
          break;
        }
      }
      return !found;
    });
    return ingredients.map((x) => {
      return {
        id: x.id,
        unit: x.unit,
        amount: x.amount,
        marked: false,
        title: x.title,
      };
    });
  },

  /**
   * @param {int} portions
   * @param {obj} recipe
   * @returns {array} ingredients
   */
  calculateIngredients(recipe, portions) {
    if (portions <= 0) {
      return [];
    }
    const scaledPortions = portions / parseInt(recipe.portions);
    const scaledIngredients = recipe.ingredients.map((x) => {
      let amount = x.amount;
      if (isNaN(amount)) {
        amount = x.amount.includes(",") ? x.amount.replace(",", ".") : x.amount;
      }
      if (amount && !isNaN(amount)) {
        amount = parseFloat(amount) * scaledPortions;
        amount = Number.isInteger(amount)
          ? parseInt(amount)
          : amount.toFixed(2);
        amount = `${amount}`.replace(".", ",");
      } else if (amount.length > 0 && isNaN(amount)) {
        console.log(amount);
        console.log(parseInt(scaledPortions));
        const factor = Number.isInteger(amount)
          ? parseInt(scaledPortions)
          : scaledPortions.toFixed(2);
        amount = `${amount} * ${factor}`;
      }

      const y = { ...x, amount: amount };
      return y;
    });
    return scaledIngredients;
  },
};

const monthes = [
  {
    id: 1,
    title: "Januar",
  },
  { id: 2, title: "Februar" },
  { id: 3, title: "März" },
  { id: 4, title: "April" },
  { id: 5, title: "Mai" },
  { id: 6, title: "Juni" },
  { id: 7, title: "Juli" },
  { id: 8, title: "August" },
  { id: 9, title: "September" },
  { id: 10, title: "Oktober" },
  { id: 11, title: "November" },
  { id: 12, title: "Dezember" },
];

const seasons = [
  { id: 1, title: "Frühling" },
  { id: 2, title: "Sommer" },
  { id: 3, title: "Herbst" },
  { id: 4, title: "Winter" },
];
