import Vue from "vue";
import VueRouter from "vue-router";
import Routes from "./routes.js";
import Store from "../store/index.js";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  routes: Routes,
  scrollBehavior() {
    setTimeout(() => {
      if (document.getElementById("mainAppView")) {
        document
          .getElementById("mainAppView")
          .scrollIntoView({ behavior: "smooth" });
      }
    }, 50);
  },
  linkActiveClass: "routerLinkActive",
});

router.beforeEach(async (to, from, next) => {
  //if (Store.getters.getRecipes.length == 0) {
  if (from.name == null) {
    console.log("on route navigation: load Data");
    await Store.dispatch("loadRecipes");
    await Store.dispatch("loadIngredients");
    await Store.dispatch("loadInformations");
    await Store.dispatch("loadShops");
    await Store.dispatch("loadShoppingList");
    next();
  } else {
    console.log("on route navigation: data already loaded");
    console.log(to);
    next();
  }
});

export default router;
