<template>
  <div :class="['item-likes', large ? 'large' : '']" @click="onClickHeart">
    <div v-if="item.likedByUser" class="thumb">
      <img src="../../assets/images/icons/thumb_red.svg" alt="" />
    </div>
    <div v-else class="thumb">
      <img src="../../assets/images/icons/thumb_black.svg" alt="" />
    </div>
    <span :class="['count', item.likedByUser ? 'red' : '']"
      >{{ item.likes }}
    </span>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  components: {},
  props: ["item", "large", "allowLike"],
  methods: {
    ...mapActions(["likeRecipe", "unlikeRecipe"]),

    onClickHeart() {
      if (!this.allowLike) {
        return;
      }
      if (this.item.likedByUser) {
        this.unlikeRecipe(this.item.id);
      } else {
        this.likeRecipe(this.item.id);
      }
    },
  },
};
</script>

<style scoped>
.item-likes {
  position: absolute;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  right: 8px;
  top: 8px;
  background: white;
  padding: 4px 8px;
  border-radius: 10px;
}

.item-likes.large {
  height: 30px;
  padding: 5px 10px;
  top: 15px;
  right: 15px;
  border-radius: 5px;
}

.count {
  font-size: 14px;
  margin-left: 5px;
}

.count.red {
  color: var(--main-color-red);
}

.thumb {
  display: flex;
}

.thumb img {
  width: 14px;
}
</style>