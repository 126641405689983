<template>
  <div class="row justify-content-center">
    <h1
      class="text-center col-10 col-md-8 col-lg-6 fst-italic text-libre fs-6"
      v-html="title"
    ></h1>
  </div>
</template>

<script>
export default {
  props: ["title"],
};
</script>

<style scoped>
h1 {
  line-height: 1.4rem;
}
</style>
