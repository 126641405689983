<template>
  <transition name="slideIn">
    <div id="sliderMenu" v-show="show" @click="close">
      <div class="container">
        <div class="p-3">
          <img class="logo" src="../assets/images/logos/logo_neu.svg" alt="" />
          <close-icon @click="close" class="float-end"></close-icon>
        </div>
      </div>
      <div class="container mt-5 p-3">
        <router-link
          :to="{ name: $routeNames.routeLikedRecipesPage }"
          class="menu-button"
        >
          <p
            class="text-uppercase"
            :class="{ aboutIsActive: 'text-red' }"
            ref="likedrecipes"
          >
            Meine Favoriten
          </p>
        </router-link>
        <router-link
          :to="{ name: $routeNames.routeShoppingListPage }"
          class="menu-button"
        >
          <p
            class="text-uppercase"
            :class="{ aboutIsActive: 'text-red' }"
            ref="shoppingList"
          >
            Einkaufsliste
          </p>
        </router-link>
        <router-link
          :to="{ name: $routeNames.routeAboutPage }"
          class="menu-button"
        >
          <p
            class="text-uppercase"
            :class="{ aboutIsActive: 'text-red' }"
            ref="about"
          >
            über mooi
          </p>
        </router-link>
        <router-link
          :to="{ name: $routeNames.routeImpressumPage }"
          class="menu-button"
        >
          <p
            class="text-uppercase"
            :class="{ aboutIsActive: 'text-red' }"
            ref="impressum"
          >
            Impressum
          </p>
        </router-link>
        <router-link
          :to="{ name: $routeNames.routePrivacyPage }"
          class="menu-button"
        >
          <p
            class="text-uppercase"
            :class="{ aboutIsActive: 'text-red' }"
            ref="privacy"
          >
            Datenschutz
          </p>
        </router-link>
      </div>
    </div>
  </transition>
</template>

<script>
import CloseIcon from "vue-material-design-icons/Close.vue";
export default {
  components: { CloseIcon },
  inject: ["showSideMenu", "activePage"],
  data() {
    return {
      show: false,
    };
  },
  methods: {
    open() {
      this.show = true;
    },
    close() {
      this.show = false;
      this.showSideMenu.value = false;
    },
  },
  computed: {},
  watch: {
    showSideMenu: {
      deep: true,
      handler(val) {
        console.log("showSideMenu: " + val.value);
        if (this.show == val.value) return;
        if (val.value) this.open();
        if (!val.value) this.close();
      },
    },
  },
};
</script>

<style scoped>
a.routerLinkActive {
  color: var(--main-color-red);
}
#sliderMenu {
  z-index: 200;
  background-color: white;
  border-right: solid 1px grey;
  width: 300px;
  height: 100vh;
  position: fixed;
}

.logo {
  width: 30px;
  height: 30px;
}

.menu-button {
  width: 100%;
  height: 1.5rem;
  text-align: left;
  margin: 1rem 0;
}

.slideIn-enter-active,
.slideIn-leave-active {
  transition: all 0.3s ease-out;
}

.slideIn-enter,
.slideIn-leave-to {
  transform: translateX(-300px);
}

.slideIn-enter-to {
  left: 0px;
}
</style>
