<template>
  <div class="row justify-content-center">
    <div class="col-12 col-md-10 col-lg-6 align-items-center">
      <base-button-style :active="true" :borderColor="$globals.COLORS_YELLOW">
        <div
          class="row justify-content-center align-items-center"
          style="height: 80px"
          @click="open"
        >
          <div class="text-libre fst-italic mr-2 col-4 fs-6">Jetzt teilen</div>
          <div class="link">
            <share-icon fillColor="#FFF" :size="25"></share-icon>
          </div>
        </div>
      </base-button-style>
    </div>
  </div>
</template>

<script>
import BaseButtonStyle from "./BaseButtonStyle.vue";
// import InstagramIcon from "vue-material-design-icons/Instagram.vue"
// import FacebookIcon from "vue-material-design-icons/Facebook.vue"
// import TwitterIcon from "vue-material-design-icons/Twitter.vue"
import ShareIcon from "vue-material-design-icons/Share.vue";

export default {
  components: {
    BaseButtonStyle,
    //   InstagramIcon,
    //    FacebookIcon,
    //     TwitterIcon,
    ShareIcon,
  },
  props: ["text"],
  methods: {
    open() {
      const url = this.$route.fullPath;
      this.$connector.share(url, this.text);
    },
  },
};
</script>

<style scoped>
.link {
  padding: 0px;
  width: 25px;
  height: 25px;
}
</style>